/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from './suite-detail-styles.module.scss'
import { Navbar, Spinner } from '../../components'
import SliderDetailsRooms from './components/slider-details-rooms'
import Accordion from './components/accordion'
import ShowRoomsImages from './components/show-room-images'
import Seo from '../../components/Seo'
import { useTranslation } from 'react-i18next';
// import panoramic from '../../assets/images/suites/reflections-group-panoramic-river-suite-full-width.jpg'
// import footerImg from '../../assets/images/suites/reflections-group-person.jpg'


const SuiteDetail = ({
  loading,
  loadingFaq,
  t,
  data,
  roomData,
  faqData,

}) => {
  const breakpoints = useBreakpoint()
  const str = _.toLower(_.get(data, 'roomTypeName'))
  const title = str.substring(0, str.indexOf('suite'))

  const roomTitle = _.get(data, 'title')
  const roomDesc = _.get(data, 'desc')

  const { translate, i18n } = useTranslation();
  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }

  const amenities = _.get(data, 'roomTypeFeatures')
  const [dynamicAmenities, setDynamicAmenities] = useState(amenities);

  useEffect(() => {
        console.log('AMENITIES', amenities, i18n.language)
        if(i18n.language.toLowerCase() === 'ja'){
            const jpAmenitiesExec = [
                `最大収容人数：2名`,
                `キングサイズベッド`,
                `ピロートップマットレス`,
                `フェザーベッド`,
                `掛け布団`,
                `Frette製（フレッテ）の高級リネン`,
                `バスタブ`,
                `シャワー`,
                `照明付きメイクアップミラー`,
                `ヘアドライヤー`,
                `バスローブ`,
                `スリッパ`,
                `椅子`,
                `目覚まし時計`,
                `客室内のセーフティボックス`,
                `デスク`,
                `アイロンとアイロン台`,
                `湯沸かしポット`,
                `ミネラルウォーター`,
                `コーヒー/紅茶メーカー`,
                `ミニバー（有料）`,
                `ワインセラー`,
                `電子レンジ`,
                `食器類`,
                `グラス`,
                `乾燥機付き洗濯機`,
                `スマートホームコントロール`,
                `ランプ、デバイス、エアコンの音声コントロール`,
                `高速インターネット（無料）`,
                `無線インターネット（無料）`,
                `2台のテレビ`,
                `ストリーミングアプリアクセス`,
                `ケーブル/衛星`,
                `国際ケーブル/衛星`,
                `モバイルからテレビへのミラーリング可能`,
                `60平方メートル/645平方フィート`,
                `エアコン完備`,
                `リビング/シッティングエリア`,
                `床から天井までの窓`,
                `マッサージ（夕方より）`,
                `新聞（要望に応じて部屋にお届けします）`,
                `バリアフリーに対応したお部屋もございます`,
                `車椅子対応のシャワー付きのお部屋はございません。`,
                `聴覚障害者向けのお部屋もございます。`
            ];
            const jpAmenitiesGarden = [
                '最大収容人数：2名',
                'クイーンサイズ/キングサイズベッド',
                'ピロートップマットレス',
                'フェザーベッド',
                '掛け布団',
                'バスタブ',
                'シャワー',
                '照明付きメイクアップミラー',
                'ヘアドライヤー',
                'バスローブ',
                'スリッパ',
                '椅子',
                '目覚まし時計',
                '客室内のセーフティボックス',
                'デスク',
                'アイロンとアイロン台',
                '湯沸かしポット',
                'ミネラルウォーター',
                'コーヒー/紅茶メーカー',
                'ミニバー（有料）',
                'ワインセラー',
                '電子レンジ',
                '食器類',
                'グラス',
                '乾燥機付き洗濯機',
                'スマートホームコントロール',
                'ランプ、デバイス、エアコンの音声コントロール',
                '高速インターネット（無料）',
                '無線インターネット（無料）',
                '2台のテレビ',
                'ストリーミングアプリアクセス',
                'ケーブル/衛星',
                '国際ケーブル/衛星',
                'モバイルからテレビへのミラーリング可能',
                '60平方メートル/645平方フィート',
                'エアコン完備',
                'リビング/シッティングエリア',
                '床から天井までの窓',
                'マッサージ（夕方より）',
                '新聞（要望に応じて部屋にお届けします）',
                'バリアフリーに対応したお部屋もございます',
                '車椅子対応のシャワー付きのお部屋はございません。',
                '聴覚障害者向けのお部屋もございます。'
            ];
            const jpAmenitiesPanorama = [
                '最大収容人数：2名',
                'キングサイズベッド',
                'ピロートップマットレス',
                'フェザーベッド',
                '掛け布団',
                'Frette製（フレッテ）の高級リネン',
                'バスタブ',
                'シャワー',
                '照明付きメイクアップミラー',
                'ヘアドライヤー',
                'バスローブ',
                'スリッパ',
                '椅子',
                '目覚まし時計',
                '客室内のセーフティボックス',
                'デスク',
                'アイロンとアイロン台',
                '湯沸かしポット',
                'ミネラルウォーター',
                'コーヒー/紅茶メーカー',
                'ミニバー（有料）',
                'ワインセラー',
                '電子レンジ',
                '食器類',
                'グラス',
                '乾燥機付き洗濯機',
                'スマートホームコントロール',
                'ランプ、デバイス、エアコンの音声コントロール',
                '高速インターネット（無料）',
                '無線インターネット（無料）',
                '2台のテレビ',
                'ストリーミングアプリアクセス',
                'ケーブル/衛星',
                '国際ケーブル/衛星',
                'モバイルからテレビへのミラーリング可能',
                '55平方メートル/592平方フィート',
                'エアコン完備',
                'リビング/シッティングエリア',
                '床から天井までの窓',
                'バルコニーとガーテン家具',
                'マッサージ（夕方より）',
                '新聞（要望に応じて部屋にお届けします）',
                'バリアフリーに対応したお部屋もございます',
                '車椅子対応のシャワー付きのお部屋はございません。',
                '聴覚障害者向けのお部屋もございます。'
            ];

            if(data && data.slug === 'executive-river-suite'){
                setDynamicAmenities(jpAmenitiesExec);
            }else if(data && data.slug === 'garden-river-suite'){
                setDynamicAmenities(jpAmenitiesGarden);
            }else if(data && data.slug === 'panorama-river-suite'){
                setDynamicAmenities(jpAmenitiesPanorama);
            }
        }else if(i18n.language.toLowerCase() === 'zh-hant'){
            const zhAmenitiesExec = [
                '最大入住人数：2',
                '大号床',
                'Pillowtop床垫、羽绒床垫、羽绒被和Frette豪华床品',
                '浴缸和淋浴',
                '带照明的化妆镜',
                '吹风机',
                '浴袍',
                '拖鞋',
                '椅子',
                '闹钟',
                '客房内保险箱',
                '书桌',
                '熨斗和熨衣板',
                '瓶装水',
                '咖啡/茶冲泡机',
                '热水壶',
                '小吃&酒吧（免费）',
                '酒窖（ 需另外付费）',
                '微波炉',
                '餐具和酒杯',
                '洗衣机烘干机',
                '智能家居控制',
                '灯具、设备和空调的语音控制',
                '高速互联网，免费提供',
                '无线互联网，免费提供',
                '2台超屏幕电视',
                '流媒体应用程序访问',
                '有线/卫星电视',
                '国际有线/卫星电视',
                '手机转换到电视',
                '60平方米/645平方英尺',
                '带空调',
                '起居/休息区',
                '落地窗',
                '阳台和花园家具',
                '晚间床铺整理服务',
                '根据要求送报纸至客房',
                '此房型提供适应机动需求的客房',
                '此房型不提供带滚动淋浴的无障碍客房',
                '此房型提供适应听觉需求的客房'
            ];
            const zhAmenitiesGarden = [
                '最大入住人数：2',
                '大/特大号床',
                '枕头顶床垫、羽绒床垫、羽绒被和Frette奢华床品',
                '浴缸和淋浴',
                '照明化妆镜',
                '吹风机',
                '浴袍',
                '拖鞋',
                '椅子',
                '闹钟',
                '客房内保险箱',
                '书桌',
                '熨斗和熨衣板',
                '免费瓶装水',
                '咖啡/茶冲泡设备',
                '即热水',
                '迷你吧，收费',
                '葡萄酒储藏室',
                '微波炉',
                '银餐具',
                '餐具和玻璃杯',
                '洗衣机兼烘干机',
                '智能家居控制',
                '灯具、设备、空调的语音控制',
                '高速互联网，免费提供',
                '无线互联网，免费提供',
                '2台电视机',
                '流媒体应用程序访问',
                '有线/卫星电视',
                '国际有线/卫星电视',
                '移动到电视的投放',
                '60平方米/645平方英尺',
                '带空调',
                '起居/休息区',
                '落地窗',
                '晚间整理服务',
                '根据要求送报纸',
                '此房型提供无障碍通道客房',
                '此房型不提供带有淋浴的无障碍客房',
                '此房型提供听觉无障碍客房'
            ];
            const zhAmenitiesPanorama = [
                '最大入住人数：2',
                '女王号床',
                'Pillowtop床垫、羽绒床垫、羽绒被和Frette豪华床品',
                '浴缸和淋浴',
                '带照明的化妆镜',
                '吹风机',
                '浴袍',
                '拖鞋',
                '椅子',
                '闹钟',
                '客房内保险箱',
                '书桌',
                '熨斗和熨衣板',
                '瓶装水',
                '咖啡/茶冲泡机',
                '热水壶',
                '小吃&酒吧（免费）',
                '酒窖（ 需另外付费）',
                '微波炉',
                '餐具和酒杯',
                '洗衣机烘干机',
                '智能家居控制',
                '灯具、设备和空调的语音控制',
                '高速互联网，免费提供',
                '无线互联网，免费提供',
                '2台超屏幕电视',
                '流媒体应用程序访问',
                '有线/卫星电视',
                '国际有线/卫星电视',
                '手机转换到电视',
                '55平方米/592平方英尺',
                '带空调',
                '起居/休息区',
                '落地窗',
                '阳台和花园家具',
                '晚间床铺整理服务',
                '根据要求送报纸至客房',
                '此房型提供适应机动需求的客房',
                '此房型不提供带滚动淋浴的无障碍客房',
                '此房型提供适应听觉需求的客房'
            ];
            if(data && data.slug === 'executive-river-suite'){
                setDynamicAmenities(zhAmenitiesExec);
            }else if(data && data.slug === 'garden-river-suite'){
                setDynamicAmenities(zhAmenitiesGarden);
            }else if(data && data.slug === 'panorama-river-suite'){
                setDynamicAmenities(zhAmenitiesPanorama);
            }
        }else{
            setDynamicAmenities(amenities);
        }
    }, [data])

  if (loading && loadingFaq) {
    return <Spinner loading={loading} />
  }

  const mainImage = _.get(data, 'main_banner', '');
  const faqImage = _.get(data, 'faq_banner', ''); 

  console.log(data)
  
  return (
    <div className={styles.suite__container}>
      <Seo title="Kamo - Suite" yoast={_.get(data, "yoast_head_json")} />
      <div className={styles.suite__box}>
        <div className={styles.suite__header}>
          <div className={styles.suite__header__image}>
            <img src={mainImage} alt="Featured Image" />
          </div>

          <div>
            <div className={styles.suite__header__content}>
              <h2>{roomTitle || title}</h2>
              <Navbar
                title={t('menu.bookWith')}
                loading={loading}
                t={t}
              />
              <p className={styles.suite__header__subtitle}>{t('suite.subtitle')}</p>
              <div
                className={styles.suite__header__description}
                dangerouslySetInnerHTML={{ __html: roomDesc || _.get(data, 'roomTypeDescription') }} />
            </div>
            <div className={styles.suite__info__box}>
              <div className={styles.suite__info__content}>
                <span>{t('suite.max')}</span>
                <p>{t('suite.maxNumber', {
                  adults: _.get(data, 'adultsIncluded', 0),
                })}</p>
              </div>
              <div className={styles.suite__info__content}>
                <span>{t('suite.min')}</span>
                {/* <p>{t('suite.minNights', { nights: _.get(data, 'min_stay', 0) })}</p> */}
                <p>{_.get(data, 'minimum_stay', 0)}</p>
                {/* {console.log(_.get(data, 'minimum_stay', 0))} */}
              </div>
              <div className={styles.suite__info__content}>
                <span>{t('suite.price')}</span>
                <p>{t('suite.priceNight', { price: _.get(data, 'starting_price', 0) })}</p>
              </div>
            </div>

          </div>

        </div>

        <div className={styles.suite__content__container}>

          <div className={styles.suite__content__box}>
            <div className={styles.suite__content__description}>
              <div>
                <h2>{_.get(data, 'section_1.title')}</h2>
              </div>
              {/* <div></div>
              <div></div>
              <div></div> */}

              <div className={styles.suite__content__text}>
                <p>{_.get(data, 'section_1.description')}</p>
              </div>
            </div>
            {
              !breakpoints.xs
                ? (
                  <SliderDetailsRooms images={_.get(data, 'section_2.images', [])}/>
                )
                : (
                  <ShowRoomsImages images={_.get(data, 'section_2.images', [])}/>
                )
            }

            <div className={styles.suite__highlights}>
              <div className={styles.suite__highlights__header}>
                <div>
                  <h2>{_.get(data, 'section_3.title')}</h2>
                  <p>{_.get(data, 'section_3.description')}</p>
                </div>
              </div>
              <div className={styles.suite__highlights__section}>
                <div className={styles.suite__highlights__section__left}>
                  <img src={_.get(data, 'section_4.image_2.url')} alt={_.get(data, 'section_4.image_2.alt')} />
                </div>
                <div className={styles.suite__highlights__section__right}>
                  <img src={_.get(data, 'section_4.image_1.url')} alt={_.get(data, 'section_4.image_1.alt')} />
                  <div>
                    <h2>{_.get(data, 'section_4.title')}</h2>
                    <p>{_.get(data, 'section_4.description')}</p>
                  </div>

                </div>
              </div>
            </div>

            <div className={styles.suite__amenities}>
              <h2>{t('suite.amenities')}</h2>
              <div className={styles.suite__amenities__content}>
                {
                  _.map(dynamicAmenities, (amenitie, idx) => <div key={amenitie + idx}><p>{amenitie}</p></div>)
                }

              </div>

            </div>
            <div className={styles.suite__footer}>
              <img src={faqImage} alt="FAQ Image" />
            </div>
            <div className={styles.suite__arcordion}>
              <h2>{t('suite.faq')}</h2>
              <div className={styles.suite__arcordion__box}>
                <Accordion data={faqData} />

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SuiteDetail
